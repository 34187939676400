import React from 'react'

import { graphql, PageProps } from 'gatsby'
// import { board } from '../../components/board/board';
import Layout from '../../layouts/index'
import { SEO } from '../../components/seo'
import { Link } from '../../components/link'
import { cleanFileType } from '../../utils/helpers'

import useGovernancePage from '../../utils/use-governance-page'
import { ChatBubbleOvalLeftEllipsisIcon, DocumentArrowDownIcon } from '@heroicons/react/24/outline'

import { useMediaQuery } from 'react-responsive'

export default function Governance(props: PageProps) {
  const governancePage = useGovernancePage()

  const governanceDocs = props.data.governanceDocs.edges

  const isMobile = useMediaQuery({
    query: '(max-width: 767px)'
  })

  return (
    <Layout
      location={props.location}
      heros={governancePage.hero}
      herosFallbackTitle={governancePage.title}
      herosFallbackSecondaryTitle="Governance"
      tabs="governance"
    >
      <div className="container flex flex-col mx-auto mt-4 lg:mt-0 mb-24">
        <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          {governancePage.body.childMarkdownRemark ? (
            <div className="inline-block min-w-full align-middle sm:px-6 lg:px-8 text-sm prose dark:prose-dark mb-10">
              <div
                dangerouslySetInnerHTML={{
                  __html: governancePage.body.childMarkdownRemark.html
                }}
                className="mx-auto"
              />
            </div>
          ) : null}
        </div>

        {governanceDocs.length > 0 ? (
          <>
            {isMobile ? (
              <div>
                {governanceDocs.map((result) => {
                  return (
                    <div
                      key={result.node.id}
                      className="w-full my-3 px-6 py-3 bg-gray-100 dark:bg-gray-900"
                    >
                      <div className="flex py-2">
                        <span className="w-20 flex-shrink-0 uppercase text-gray-500 text-xs font-medium tracking-wider">
                          Title
                        </span>
                        <div className="text-sm text-gray-900 dark:text-gray-200">
                          {result.node.title}
                        </div>
                      </div>
                      <div className="flex py-2">
                        <span className="w-20 flex-shrink-0 uppercase text-gray-500 text-xs font-medium tracking-wider">
                          Type
                        </span>
                        <Link
                          to={result.node.doc.file.url}
                          className="flex items-center text-sm uppercase text-gray-900 dark:text-gray-200 hover:underline"
                        >
                          <DocumentArrowDownIcon
                            className="w-6 h-6 mr-1 -ml-1 opacity-50"
                            aria-hidden="true"
                          />
                          {cleanFileType(result.node.doc.file.contentType)}
                        </Link>
                      </div>
                    </div>
                  )
                })}
              </div>
            ) : (
              <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8 mb-4">
                <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                  <table className="min-w-full overflow-hidden divide-y divide-gray-200 shadow dark:divide-gray-700 sm:rounded-lg">
                    <thead className="bg-gray-100 dark:bg-gray-800">
                      <tr>
                        <th
                          scope="col"
                          className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
                        >
                          Title
                        </th>
                        <th
                          scope="col"
                          className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
                        >
                          Type
                        </th>
                        <th
                          scope="col"
                          className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
                        >
                          View
                        </th>
                      </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200 dark:divide-gray-700 dark:bg-gray-900">
                      {governanceDocs.map((result) => {
                        return (
                          <tr key={result.node.id}>
                            <td className="px-6 py-4 text-sm font-medium text-gray-900 dark:text-gray-200 whitespace-nowrap">
                              {result.node.title}
                            </td>
                            <td className="px-6 py-4 text-sm font-medium uppercase text-gray-900 dark:text-gray-200 whitespace-nowrap">
                              {cleanFileType(result.node.doc.file.contentType)}
                            </td>
                            <td className="px-6 py-4 text-sm text-gray-500 dark:text-gray-200 whitespace-nowrap">
                              <Link
                                to={result.node.doc.file.url}
                                className="flex items-center font-medium hover:underline"
                              >
                                <DocumentArrowDownIcon
                                  className="w-6 h-6 mr-1 opacity-50"
                                  aria-hidden="true"
                                />{' '}
                                View
                              </Link>
                            </td>
                          </tr>
                        )
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            )}
          </>
        ) : (
          <>
            {governancePage.noResults ? (
              <div className="flex flex-col items-center justify-center py-20 mx-auto text-lg text-black bg-gray-100 rounded dark:bg-gray-900 dark:text-white">
                <ChatBubbleOvalLeftEllipsisIcon className="w-12 h-12 text-gray-500" />
                <h6 className="flex justify-center mx-auto text-gray-500 text-md">
                  {governancePage.noResults.noResults}
                </h6>
              </div>
            ) : null}
          </>
        )}
      </div>
    </Layout>
  )
}

export const Head = () => (
  <SEO />
)

export const pageQuery = graphql`
  query {
    governanceDocs: allContentfulGovernanceDocuments(
      filter: {shown: {eq: true}, title: {ne: null}}
      sort: { fields: [sticky, weight], order: [DESC, ASC] }
    ) {
      edges {
        node {
          doc: file {
            file {
              url
              contentType
            }
          }
          id
          title
        }
      }
    }
  }
`
